::-webkit-scrollbar {
  display: block;
  width: 15px;
}
*:hover::-webkit-scrollbar {
  display: block;
}
::-webkit-scrollbar-track {
  background-color: var(--btn-color);
  border: 5px solid transparent;
  background-clip: padding-box;
  border-radius: 20px;
}
::-webkit-scrollbar-thumb {
  background: rgba(255,255,255,0.25);
  border: 5px solid transparent;
  background-clip: padding-box;
  border-radius: 20px;
}
::-webkit-scrollbar-thumb:vertical:hover { background-color: var(--text-color) }
::-webkit-scrollbar-thumb:vertical:active { background-color: var(--text-color) }

.background3d{
  z-index:-1;
  position:fixed;
  left:0;
  top:0;
}

:root{
  --app-background-color:rgba(0,0,0,0.5);
  --app-background-image:url(https://source.unsplash.com/random/?cute&dog);

  --navigation-background:rgba(0,0,0,0.85);

  --navigation-open-button:rgba(0,0,0,0.75);
  --navigation-close-button:rgba(117, 15, 15, 0.5);

  --navigation-tab-button-background:rgba(255,255,255,0.0);
  --navigation-tab-button-background-hover:rgba(255,255,255,0.5);
  --navigation-tab-button-border: rgba(255,255,255,1.0);

  --eventcard-unlocked-background:#008b8b;
  --eventcard-unlocked-outline: #008b8b;
  --eventcard-locked-background: #cd5c5c;
  --eventcard-locked-outline: #cd5c5c;

  --text-color: #ffffff;
  --text-color-locked: #cd5c5c;

  --margin-correction:74px;
}

html,body{
  margin:0;
  padding:0;
  display:grid;
  place-content:center;
  height:100vh;
}

.auth-form{
  display:flex;
  flex-flow:column;
}

.auth-form input{
  border-radius:5px;
  font-size:24px;
  margin:5px;
}

.status-header{
  text-align: center;
  color:var(--text-color);
  font-size:30px;
}

.status-text{
  text-align: center;
  color:var(--text-color);
  font-size:24px;
}

.verify-platforms{
  display:flex;
  align-self:center;
}

.platform-button{
  display:flex;
  flex-flow:row;
  align-items:center;
  margin:10px;
  padding:10px;
  border-radius:10px;
  font-size:24px;
  color:white;
  background-color: var(--eventcard-unlocked-background);
  border: 2px solid white;
}

.platform-button span{
  margin-left:10px;
}

.modcheck-button{
  margin:5px;
  background-color: var(--eventcard-unlocked-background);
  padding:5px 15px 5px 15px;
  color:var(--text-color);
  font-size:36px;
  border-radius: 15px;
  border: 2px solid white;
}

.modcheck-button:disabled{
  filter:saturate(0);
  pointer-events:none;
}

.modcheck-button:hover,
.platform-button:hover{
  filter:brightness(1.2);
}

.App {
  display:flex;
  flex-flow:column;
  align-items: center;
  padding:30px;
  background-color:rgba(0, 0, 0, 0.75);
  border-radius:10px;
}

.header{
  color:white;
  font-size:36px;
  font-weight:bold;
  display:flex;
  text-align: center;
  margin-bottom:10px;
}

.match{
  outline: solid green 3px;
}

.nomatch{
  outline: solid red 3px;
}

@keyframes spin{
  from{
    transform:rotate(0);
  }
  to{
    transform:rotate(360deg);
  }
}

.loading-icon{
  align-self:center;
}

.loading-circle{
  animation-name:spin;
  animation-duration:1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@media screen and (max-width: 600px) {
  html{
    height:fill-available;
    height: -webkit-fill-available;
  }

  .App {
    max-height:calc(94vh - var(--margin-correction));
  }
}